// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/frontPage.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-plan-your-visit-js": () => import("./../../../src/templates/planYourVisit.js" /* webpackChunkName: "component---src-templates-plan-your-visit-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-single-development-js": () => import("./../../../src/templates/singleDevelopment.js" /* webpackChunkName: "component---src-templates-single-development-js" */),
  "component---src-templates-single-js": () => import("./../../../src/templates/single.js" /* webpackChunkName: "component---src-templates-single-js" */),
  "component---src-templates-single-place-js": () => import("./../../../src/templates/singlePlace.js" /* webpackChunkName: "component---src-templates-single-place-js" */),
  "component---src-templates-single-whats-on-js": () => import("./../../../src/templates/singleWhatsOn.js" /* webpackChunkName: "component---src-templates-single-whats-on-js" */),
  "component---src-templates-whats-on-js": () => import("./../../../src/templates/whatsOn.js" /* webpackChunkName: "component---src-templates-whats-on-js" */)
}

