import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIsMobile } from 'utils'

export const MainContext = React.createContext()

const Provider = props => {
	const [isHeaderInverted, setIsHeaderInverted] = useState(false)
	const [hasScrolled, setHasScrolled] = useState(false)
	const invertedHeaderSections = useRef([])
	const [searchOpen, setSearchOpen] = useState(false)

	const isHeaderInvertedRef = useRef(isHeaderInverted)
	isHeaderInvertedRef.current = isHeaderInverted

	const hasScrolledRef = useRef(hasScrolled)
	hasScrolledRef.current = hasScrolled

	const isMobile = useIsMobile()

	const _window = typeof window !== 'undefined' ? window : null
	
	const addInvertedHeaderSection = ref => {
		const { current } = invertedHeaderSections

		if(current.indexOf(ref) === -1){
			current.push(ref)
		}

		invertedHeaderSections.current = current

		checkInvertedHeaderOffsets()
	}

	useEffect(() => {
		if(isMobile && _window){
			const viewport = document.getElementsByName('viewport')

			const ratio = _window.screen.width / 500

			viewport[0].content = `width=500, initial-scale=${ ratio }, maximum-scale=${ ratio }`
		}
	}, [isMobile, _window])

	useEffect(() => {
		let scrollHandler = null

		if(_window){
			scrollHandler = _window.addEventListener('scroll', checkInvertedHeaderOffsets)
		}

		return () => {
			if(scrollHandler){
				scrollHandler.remove()
			}
		}
	}, [])

	const checkInvertedHeaderOffsets = () => {
		const invertRefs = invertedHeaderSections.current
		let _isHeaderInverted = false

		if(_window){
			const scrollOffset = _window.pageYOffset

			if(scrollOffset > 10 && !hasScrolledRef.current){
				setHasScrolled(true)
			}else if(scrollOffset <= 10 && hasScrolledRef.current){
				setHasScrolled(false)
			}
		}

		invertRefs.forEach(ref => {

			const offsets = ref.getBoundingClientRect()

			const isOverSection = offsets.top < 120 && (offsets.top + offsets.height) > 120

			if(isOverSection){
				_isHeaderInverted = true
			}
		})

		if(_isHeaderInverted !== isHeaderInvertedRef.current){
			setIsHeaderInverted(_isHeaderInverted)
		}
	}

	const resetHeader = () => {
		invertedHeaderSections.current = []
	}

	const optionsQuery = useStaticQuery(graphql`
		query optionsQuery {
			siteFields {
				options {
					options {
						generalOpeningHours {
							day
							openTime
							closeTime
						}
						socialMedia {
							url
							company
						}
						address
						addressLink
						phone
						emailAddress
						websitePopup {
							title
							subtitle
							copy
							link {
								title
								url
							}
							image {
								mediaItemUrl
							}
							hide
							showSubscribePopup
						}
					}
				}
			}
		}
	`)
	
	const { options } = optionsQuery.siteFields.options

	return (
		<MainContext.Provider 
			value={{
				options,
				isHeaderInverted,
				setIsHeaderInverted,
				addInvertedHeaderSection,
				hasScrolled,
				checkInvertedHeaderOffsets,
				resetHeader,
				searchOpen,
				setSearchOpen
			}}
		>
			{props.children}
		</MainContext.Provider>
	)
}

export default ({ element }) => (
	<Provider>
		{element}
	</Provider>
)
