
import React, { useEffect, useState } from "react"
import Moment from 'moment'

export const nl2br = str => {
    if (typeof str === 'undefined' || str === null) {
        return ''
    }

    const breakTag = '<br />'

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export const emailIsValid = email => {
    //eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
}

export const Date = ({ date, includeYear }) => {
    return (
        <>
            {includeYear ? Moment(date).format('Do MMMM YYYY') : Moment(date).format('Do MMMM')}
        </>
    )
}

export const isActiveLink = (location, url) => {
    const { pathname } = location

    const _url = url.replace('/', '')
    const _pathname = pathname.replace('/', '')
    const isActive = _pathname && _pathname.indexOf(_url) !== -1

    return isActive
}

export const getUrlParam = name => {
    const url = typeof window !== 'undefined' ? window.location.href : ''
    //eslint-disable-next-line
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]")
    var regexS = "[\\?&]" + name + "=([^&#]*)"
    var regex = new RegExp(regexS)
    var results = regex.exec(url)
    return results == null ? null : results[1]
}

export const useIsMobile = () => {
    const [_isMobile, setIsMobile] = useState(false)

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        setTimeout(() => {
            if (_window) {
                setIsMobile(_window.innerWidth <= 500)
            }
        }, 1)
    }, [_window])

    return _isMobile
}

export const DevelopmentStatus = ({ value }) => {

    let statusLabel = ''

    switch (value) {
        case 'comingsoon':
            statusLabel = ' Coming Soon'
            break
        case 'nowleasing':
            statusLabel = 'Now Leasing'
            break
        case 'available':
            statusLabel = 'Available'
            break
        default:
            statusLabel = value
            break
    }

    return (
        <>
            {statusLabel}
        </>
    )
}

export const EventType = ({ name }) => {
    let typeName = ''

    switch (name) {
        case 'Activities':
            typeName = ' Activity'
            break
        case 'Events':
            typeName = ' Event'
            break
        case 'Offers':
            typeName = ' Offer'
            break
        case 'Free':
            typeName = ' Free Event'
            break
        default:
            typeName = name
            break
    }

    return (
        <>
            {typeName}
        </>
    )
}
